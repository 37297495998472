<template>
  <div class="grid-default container py-28 text-neutral-800 lg:py-36">
    <Heading
      v-if="data.title"
      :content="data.title"
      :level="2"
      class="col-span-9 mb-4 font-bold lg:mb-6"
    />
    <div
      v-if="data.bellowTitle"
      class="col-span-12 mb-10 text-base lg:col-span-9 lg:mb-20 lg:text-xl"
    >
      {{ data.bellowTitle }}
    </div>
    <div class="col-span-12 mb-6 lg:col-span-4 lg:mb-0">
      <template v-if="activeIndex === undefined && isDesktop">
        <Image
          v-if="data.fallbackImage"
          loading="lazy"
          class="aspect-square rounded-[40px] object-cover hidden lg:block"
          sizes="(min-width: 1920px) 597px, (min-width: 1400px) 405px, (min-width: 1040px) calc(26.47vw + 40px), calc(100vw - 32px)"
          :src="
            data.fallbackImage.responsiveImage
              ? data.fallbackImage?.responsiveImage.src
              : data.fallbackImage.url
          "
          :srcset="
            data.fallbackImage.responsiveImage
              ? data.fallbackImage.responsiveImage.webpSrcSet
              : undefined
          "
          :width="
            data.fallbackImage.responsiveImage
              ? data.fallbackImage.responsiveImage.width
              : data.fallbackImage.width
          "
          :height="
            data.fallbackImage.responsiveImage
              ? data.fallbackImage.responsiveImage.height
              : data.fallbackImage.height
          "
          :alt="data.fallbackImage.alt ? data.fallbackImage.alt : ''"
        />
      </template>
      <template v-for="(link, index) in data.links" :key="index">
        <div v-if="activeIndex === index">
          <Image
            v-if="link.image"
            loading="lazy"
            class="aspect-square rounded-[40px] object-cover"
            sizes="(min-width: 1920px) 597px, (min-width: 1400px) 405px, (min-width: 1040px) calc(26.47vw + 40px), (min-width: 680px) 600px, 92.22vw"
            :src="link.image.responsiveImage ? link.image.responsiveImage.src : link.image.url"
            :srcset="link.image.responsiveImage ? link.image.responsiveImage.webpSrcSet : undefined"
            :width="
              link.image.responsiveImage ? link.image.responsiveImage.width : link.image.width
            "
            :height="
              link.image.responsiveImage ? link.image.responsiveImage.height : link.image.height
            "
            :alt="link.image.alt ? link.image.alt : ''"
          />
        </div>
      </template>
    </div>

    <ul class="col-span-12 lg:col-span-8">
      <li
        v-for="(link, index) in data.links"
        :key="index"
        class="border-b border-neutral-300 text-xl last:border-0 hover:lg:border-transparent"
        :class="{ '!border-transparent': activeIndex && activeIndex - 1 === index }"
        @mouseover="activeIndex = index"
        @mouseleave="activeIndex = undefined"
      >
        <div
          class="text-pink-500 group flex items-center justify-between gap-4 rounded-[40px] px-4 py-[1.875rem] transition-all duration-500 ease-in-out lg:h-[136px] lg:px-10 lg:text-4xl hover:lg:bg-neutral-800 hover:lg:text-white"
          role="button"
          @click="handleRedirect(link)"
        >
          <span
            class="bg-gradient-to-r from-white to-white bg-[length:0%_2px] bg-left-bottom bg-no-repeat transition-all duration-500 ease-out group-hover:bg-[length:100%_2px]"
          >
            {{ link.title }}
          </span>
          <Icon
            name="arrow-down-left"
            :color="theme.colors.neutral[800]"
            size="lg"
            class="-rotate-90 lg:hidden"
          />
          <Transition name="fade">
            <span class="hidden lg:block">
              <Button
                :label="$t('more')"
                :size="'large'"
                :to="localePathByType(link.link?.__typename as string, link.link?.slug as string)"
                theme="dark"
                :is-transparent-bg-neutral="true"
                noPrefetch
              />
            </span>
          </Transition>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { theme } from '#tailwind-config'

const { localePathByType } = useLocalePathByType()
const router = useRouter()
const { isDesktop } = useDevice()

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const activeIndex = ref<number | undefined>()

function handleRedirect(link: any) {
  router.push(localePathByType(link.link?.__typename as string, link.link?.slug as string))
}
</script>
